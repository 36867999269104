import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ContactFormFields from "../../static/forms/contact_form.json"
import ListFormFields from "../../static/forms/list_your_property_form.json"
import CitizenFormFields from "../../static/forms/apply_for_citizenship.json"

import FormLayout from "../components/FormLayout/FormLayout"
import Layout from "../components/layout"
import SEO from "../components/seo"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
const EnquiryIntro = loadable(() =>
  import("../components/EnquiryIntro/EnquiryIntro")
)
const DefaultForm = loadable(() =>
  import("../components/forms/default-form-layout")
)

const FormTemplate = (props) => {
  const { data } = props
  const PageData = data?.strapiPage
  const to_email_id = props?.location?.state?.data?.to_email_id

  return (
    <Layout dark>
      <div className="layout-padding-top">
        <BreadcrumbModule
          subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
          subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
          parentlabel={
            PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title
          }
          parentlink={
            PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug
          }
          title={PageData.title}
        />

        {PageData?.add_page_modules?.length > 0 &&
          PageData.add_page_modules?.map((module, index) => {
            return (
              <>
                {module.strapi_component === "components.add-form" && (
                  <div className="form-module">
                    <Container>
                      <Row>
                        <Col xl={7}>
                          <div className="form-block">
                            <EnquiryIntro
                              title={module.title}
                              content={module.content?.data?.content}
                            />

                            <FormLayout>
                              <DefaultForm
                                fields={
                                  module.choose_form === "general_enquire"
                                    ? ContactFormFields 
                                    : module.choose_form === "list_your_property" ? ListFormFields
                                    : CitizenFormFields
                                }
                                classname="enquiry-form-wrapper"
                                to_email_id={to_email_id}
                                // emailData={emailData}
                              />
                            </FormLayout>
                          </div>
                        </Col>
                        <Col xl={1} />
                        <Col xl={4}>
                          <div className="right-text-block">
                            {module?.right_content?.data?.right_content && (
                              <ContentModule
                                Content={
                                  module?.right_content?.data?.right_content
                                }
                              />
                            )}
                            {module?.add_item?.length > 0 && (
                              <ul>
                                {module?.add_item?.map((item, i) => {
                                  return (
                                    <li>
                                      <span>
                                        <img src={item?.icon.url} alt="icon" />
                                      </span>{" "}
                                      <span>{item.text}</span>
                                    </li>
                                  )
                                })}
                              </ul>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                )}
              </>
            )
          })}
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    />
  )
}

export default FormTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_ADD_FORM {
          id
          title
          strapi_component
          choose_form
          content {
            data {
              content
            }
          }
          add_item {
            text
            icon {
              url
            }
          }
          right_content {
            data {
              right_content
            }
          }
        }
      }
    }
  }
`
